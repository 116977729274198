var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticStyle: { "text-align": "left" },
      attrs: {
        title: `Apply ${_vm.isCreditNote ? "Credit" : "Debit"} Note`,
        visible: _vm.dialogSelectCdNote,
        height: "fit-content",
      },
      on: {
        open: _vm.handleCdNoteDialogOpen,
        close: _vm.handleSelectCdNoteDialogClose,
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.cdNoteTableLoading,
                  expression: "cdNoteTableLoading",
                },
              ],
              ref: "cdNoteTable",
              attrs: {
                data: _vm.cdNoteTableData,
                height: "48vh",
                "cell-style": { padding: "0", height: "60px" },
              },
              on: { "selection-change": _vm.handleSelectChange },
            },
            [
              _c("el-table-column", {
                key: "checkbox",
                attrs: { type: "selection", "min-width": "55" },
              }),
              _c("el-table-column", {
                attrs: { label: "Invoice Number" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                scope.row.createdByInvoice
                                  .systemGeneratedInvoiceNo
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Vessel/ Terminal" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                scope.row.createdByInvoice.orderVesselName
                                  ? scope.row.createdByInvoice.orderVesselName
                                  : scope.row.createdByInvoice.orderTerminal
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Supply Date" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatDate(new Date(scope.row.supplyDate))
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Note Total", "show-overflow-tooltip": "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            " $" +
                              _vm._s(
                                _vm.numberWithCommas(scope.row.noteTotal, 3)
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Date Created" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatDate(new Date(scope.row.noteDate))
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "el-dialog__footer" },
        [
          _c(
            "el-button",
            {
              attrs: { outline: "", round: "" },
              on: { click: _vm.handleSelectCdNoteDialogClose },
            },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "el-button",
            {
              attrs: { round: "", type: "primary" },
              on: { click: _vm.handleConfirmCdNoteDialogClick },
            },
            [_vm._v(" Confirm ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }